import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from "../components/NotFound";

Vue.use(VueRouter);

let router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/', // -> /login
            name: 'Home',
            meta: {
                title: 'Home',
            },
            component: () => import(/* webpackChunkName: "auth-view" */ './views/HomeScreen.vue'),
        },
        {
            path: '/watch/:id(\\d+)',
            name: 'watch',
            meta: {
                title: 'Watch',
            },
            component: () => import(/* webpackChunkName: "watch-view" */ './views/WatchGameScreen.vue'),
        },
    ]
});

router.beforeEach((to, from, next) => {
    if (to.name === 'login' || to.name === 'register' || to.name === 'verify-notice' || to.name === 'password-reset-request'
        || to.name === 'password-reset' || to.name === 'ErrorPage' || to.name === 'buy' || to.name === 'verify' || to.name === 'Select package') {
        next()
    } else {
        Vue.$storage.set('last_page', to.path);
        Vue.$storage.set('last_page_params', to.params);
        next()
    }
});

export default router;
